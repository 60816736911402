import { Box, Container, Flex, Show, useTheme } from '@chakra-ui/react'
import { useGetThemeQuery } from '@core/app/api/globalApi'
import { EmployeeHeader } from '@core/components/EmployeeHeader'
import { Footer } from '@core/components/Footer/Footer'
import { Header } from '@core/components/Header/Header'
import { MobileHeader } from '@core/components/mobileHeader/mobileHeader'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import useIsEmployee from '@core/utils/hooks/useIsEmployee'
import { ReactNode, useEffect } from 'react'

interface Props {
    children: ReactNode
    useContainer?: boolean
    useMobileHeader?: boolean
    showFooter?: boolean
    showHeader?: boolean
    bgColor?: string
    isProfilePage?: boolean
    fixedHeader?: boolean
    isEmployee?: boolean
}

export const Layout = ({
    children,
    bgColor,
    useContainer = true,
    useMobileHeader = true,
    showFooter = true,
    showHeader = true,
    isProfilePage = false,
    fixedHeader  = false,
    isEmployee = false,
}: Props): JSX.Element => {
    const { appTheme, updateTheme, isExpired } = useAppTheme()
    const { data: themeData } = useGetThemeQuery({}, { skip: isExpired })
    const theme = useTheme()

    const bg = appTheme?.newSettings?.general?.Background
        ? `#${appTheme?.newSettings?.general?.Background}`
        : theme.colors.bg[3]

    useEffect(() => {
        if (themeData) {
            updateTheme(themeData)
        }
    }, [themeData])

    const getBackgroundColor = () => {
        // if (isLoading) {
        //     return 'transparent'
        // }
        return isEmployee ? '#EDEEF0' : bgColor || bg
    }

    return (
        <Flex minH={'100vh'} flexDirection={'column'} id={'app-container'}>
            {showHeader && !isEmployee && <Header isProfilePage={isProfilePage} fixedHeader={fixedHeader}/>}
            {showHeader && isEmployee && <EmployeeHeader />}
            <Box as={'main'} flex={1} h={'full'} backgroundColor={getBackgroundColor()}>
                {useContainer ? (
                    <Container
                        maxW={{
                            base: isProfilePage ? 'container.md' : 'container.sm',
                            lg: isProfilePage ? 'container.lg' : 'container.sm',
                            xl: 'container.xl',
                        }}
                    >
                        {children}
                    </Container>
                ) : (
                    children
                )}
            </Box>
            {showFooter && <Footer />}
            <Show below={isProfilePage ? 'lg' : 'xl'}>
                <MobileHeader useMobileHeader={useMobileHeader} />
            </Show>
        </Flex>
    )
}
