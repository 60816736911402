import { Checkbox, Text } from '@chakra-ui/react'
import {ChangeEvent} from "react";

interface Props {
    id: number | string
    name: string
    selected: boolean
    type: string
    onSelect: (id: number | string, type: string, value: boolean) => void
}

export const CheckboxItem = ({
    id,
    name,
    selected,
    type,
    onSelect,
}: Props): JSX.Element => {
    return (
        <Checkbox
            id={`${id}`}
            isChecked={selected}
            onChange={(e:  ChangeEvent<HTMLInputElement>) => {
                onSelect(id, type, e.target.checked)
            }}
            name={type}
            _hover={{
                '& > span:first-of-type': {
                    borderColor: 'blue.500',
                },
            }}
        >
            <Text
                fontSize={'sm'}
                color={selected ? 'text.main' : 'text.secondary'}
                fontWeight={selected ? 'medium' : 'normal'}
                lineHeight={4}
            >
                {name}
            </Text>
        </Checkbox>
    )
}
