import { Icon } from '@chakra-ui/react'

export const IconJobType = (): JSX.Element => {
    return (
        <Icon viewBox={`0 0 12 12`} w={`12px`} h={`12px`} fill="none">
            <g clip-path="url(#clip0_5909_221020)">
                <path
                    d="M6 0C7.65703 0 9 1.34297 9 3C9 4.65703 7.65703 6 6 6C4.34297 6 3 4.65703 3 3C3 1.34297 4.34297 0 6 0ZM5.65078 8.41875L5.21484 7.69219C5.06484 7.44141 5.24531 7.125 5.53594 7.125H6H6.46172C6.75234 7.125 6.93281 7.44375 6.78281 7.69219L6.34688 8.41875L7.12969 11.3227L8.05547 7.54453C9.86484 7.82578 11.25 9.39141 11.25 11.2805C11.25 11.6789 10.9266 12 10.5305 12H6.96328H5.03672H1.46953C1.07344 12 0.75 11.6766 0.75 11.2805C0.75 9.39141 2.13516 7.82578 3.94453 7.54453L4.87031 11.3227L5.65312 8.41875H5.65078Z"
                    fill="#4F4F4F"
                />
            </g>
            <defs>
                <clipPath id="clip0_5909_221020">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
