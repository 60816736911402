import { Box, Button, Container, Flex, HStack, Input, InputGroup, InputLeftElement, Show, Text } from '@chakra-ui/react'
import { FilterSettings, PostsItem } from '@core/app/types/postsApiType'
import { useTranslation } from 'react-i18next'

import { IconFilter } from '@core/assets/icons/filter'
import { IconSearch } from '@core/assets/icons/search'
import { IconUndo } from '@core/assets/icons/undo'

interface Props {
    data: PostsItem | undefined
    settings?: FilterSettings
    searchQuery: (value: string) => void
    open: Function
    onReset: () => void
}

export const TopTab = ({ data, settings, searchQuery, open, onReset }: Props): JSX.Element => {
    const { t } = useTranslation()
    const isFiltersExist = settings && (settings?.industry || settings?.area || settings?.jobType || settings?.office)

    const reset = () => {
        onReset && onReset()
    }

    const openFilter = () => {
        open()
    }

    let dataLength: number = 0
    if (data) {
        dataLength = Object.keys(data || {}).length
    }

    return (
        <Box
            bg={'bg.1'}
            border={isFiltersExist ? '1px solid' : 'none'}
            borderColor={'border.1'}
            pt={isFiltersExist ? 1 : 0}
            pb={isFiltersExist ? 1 : 0}
            id="topbar"
        >
            <Container maxW={{ base: 'container.sm', xl: 'container.xl' }}>
                <Flex w={'full'} justifyContent={'space-between'}>
                    {isFiltersExist && (
                        <HStack>
                            <Text as="span" fontSize="lg" fontWeight="semibold">{dataLength}</Text>
                            <Text fontSize="sm" fontWeight="normal" textTransform="lowercase">{dataLength > 1 ? t('2413') : t('5369')}</Text>
                        </HStack>
                    )}

                    <Show above={'xl'}>
                        {isFiltersExist && (
                            <Button variant={'default'} p={0} onClick={() => reset()} aria-label={`${t('6475')}`}>
                                <IconUndo w={'14'} h={'14'} fill={'#828282'} />
                            </Button>
                        )}
                    </Show>
                    <Show below={'xl'}>
                        {isFiltersExist && (
                            <Button variant={'default'} p={0} onClick={() => openFilter()} aria-label={`${t('6448')}`}>
                                <IconFilter />
                            </Button>
                        )}
                    </Show>
                </Flex>
                <Show above={'xl'}>
                    <Flex w={'full'} justifyContent={'space-between'} gap={2} mt={2}>
                        {settings?.boxStyle && (
                            <InputGroup>
                                <InputLeftElement pointerEvents={'none'}>
                                    <IconSearch w={'16'} h={'16'} fill={'#333333'} />
                                </InputLeftElement>
                                <Input placeholder={`${t('2493')}`} onChange={(e) => searchQuery(e.target.value)} />
                            </InputGroup>
                        )}
                        {isFiltersExist && (
                            <Button variant={'default'} p={0} onClick={() => openFilter()} aria-label={`${t('6448')}`}>
                                <IconFilter />
                            </Button>
                        )}
                    </Flex>
                </Show>
            </Container>
        </Box>
    )
}
