import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Flex, HStack, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { FilterSettings, PostsItem } from '@core/app/types/postsApiType'
import { Localization } from '@core/app/types/globalApiType'

import { Post } from '@page/posts/components/Post/Post'
import { AppButton } from '@core/components/AppButton'

import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'

import { IconUndo } from '@core/assets/icons/undo'
import noJobs from '@page/posts/assets/images/empty_state.svg'
import { DefaultSelect } from '@core/components/Select/Select'

interface Posts {
    data: PostsItem | undefined
    settings: FilterSettings | undefined
    reset: () => void
    onSortChange: (sortValue: string) => void;
}

export const PostsList = ({ data, settings, reset, onSortChange }: Posts): JSX.Element => {
    const { t } = useTranslation()
    const { appTheme } = useAppTheme()
    const [localization, setLocalization] = useState<Localization>({})
    const [sortValue, setSortValue] = useState<string>('')
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const { newSettings } = appTheme || {newSettings: {general: {}}}
    const { general } = newSettings
    const { BackgroundText } = general

    const handleChange = (selectedOption: any) => {
        const value = selectedOption.value
        setSortValue(value)
        onSortChange(value)
    }

    let dataLength: number = 0
    if (data) {
        dataLength = Object.keys(data || {}).length
    }

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    return (
        <>
            {dataLength === 0 ? (
                <Box
                    w={'full'}
                    h={'full'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    mt={'200px'}
                >
                    <Flex flexDirection={'column'} alignItems={'center'} w={'336px'}>
                        <Image src={noJobs} />
                        <Text fontSize={'20px'} fontWeight={'600'} mt={'25px'} mb={'10px'}>
                            {t('8377')}
                        </Text>
                        <Text fontSize={'14px'} fontWeight={'500'} color={'text.secondary'}>
                            {t('8378')}.
                        </Text>
                        <Text fontSize={'14px'} fontWeight={'500'} color={'text.secondary'} mt={'3px'}>
                            {t('7845')}.
                        </Text>
                        <AppButton
                            translate="no"
                            title={t('6475')}
                            leftIcon={<IconUndo w={'14'} h={'14'} fill={'#fff'} />}
                            variant={'primary'}
                            w={'184px'}
                            mt={6}
                            onClick={() => reset()}
                        />
                    </Flex>
                </Box>
            ) : (
                <Box w={{ base: 'full', xl: '800px' }}>
                     <Container maxW={{ base: 'container.sm', xl: 'container.xl' }} px={isDesktop ? 0 : 4}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} mb={5}>
                            {isDesktop && (
                                <HStack>
                                    <Text
                                        as="span"
                                        fontSize="lg"
                                        fontWeight="semibold"
                                        color={
                                            settings?.boxStyle ? 'text.dark' : `#${BackgroundText ? BackgroundText : '000000'}`
                                        }
                                    >
                                        {dataLength}
                                    </Text>
                                    <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        textTransform="lowercase"
                                        color={
                                            settings?.boxStyle ? 'text.dark' : `#${BackgroundText ? BackgroundText : '000000'}`
                                        }
                                     >
                                        {localization[11] ? localization[11] : t('2413')}
                                    </Text>
                                </HStack>
                            )}
                            <Box maxW={{base: 'full', xl: '220px'}} w={'100%'}>
                                <DefaultSelect
                                    className={'max-w-[100%]'}
                                    placeholder={t('4125')}
                                    options={[
                                        { label: t('7915'), value: 'newest' },
                                        { label: t('7916'), value: 'oldest' },
                                    ]}
                                    onChange={handleChange}
                                    values={sortValue}
                                    searchable={false}
                                    isBorderOnHover={true}
                                    placeholderHoverBgColor="#F5F5F5"
                                />
                            </Box>
                        </Box>
                     </Container>
                    <VStack w='full' gap={4}>
                        {data
                            ? Object.values(data).map((post: PostsItem[0]) => <Post postData={post} key={post.AdID} />)
                            : null}
                    </VStack>
                </Box>
            )}
        </>
    )
}
