import { Icon } from '@chakra-ui/react'

export const IconFilter = (): JSX.Element => {
    return (
        <Icon viewBox={`0 0 20 20`} w={`20px`} h={`16px`} fill="none">
            <path
                d="M19.1666 11.6667C19.5027 11.6667 19.7777 11.9417 19.7777 12.2778C19.7777 12.6139 19.5027 12.8889 19.1666 12.8889H6.27217C5.95515 14.283 4.75585 15.3334 3.27772 15.3334C1.59029 15.3334 0.222168 13.966 0.222168 12.2778C0.222168 10.5896 1.59029 9.22224 3.27772 9.22224C4.75585 9.22224 5.95515 10.2726 6.27217 11.6667H19.1666ZM1.44439 12.2778C1.44439 13.29 2.26519 14.1111 3.27772 14.1111C4.28988 14.1111 5.11106 13.29 5.11106 12.2778C5.11106 11.2656 4.28988 10.4445 3.27772 10.4445C2.26519 10.4445 1.44439 11.2656 1.44439 12.2778ZM13.7277 3.11113C14.0104 1.71627 15.244 0.666687 16.7222 0.666687C18.4104 0.666687 19.7777 2.03481 19.7777 3.72224C19.7777 5.41044 18.4104 6.7778 16.7222 6.7778C15.244 6.7778 14.0104 5.72745 13.7277 4.33335H0.833279C0.495793 4.33335 0.222168 4.05835 0.222168 3.72224C0.222168 3.38613 0.495793 3.11113 0.833279 3.11113H13.7277ZM16.7222 5.55558C17.7343 5.55558 18.5555 4.7344 18.5555 3.72224C18.5555 2.71009 17.7343 1.88891 16.7222 1.88891C15.71 1.88891 14.8888 2.71009 14.8888 3.72224C14.8888 4.7344 15.71 5.55558 16.7222 5.55558Z"
                fill="#828282"
            />
        </Icon>
    )
}
