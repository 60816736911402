import { Icon } from '@chakra-ui/react'

type Props = {
    w: string
    h: string
    fill?: string
}

export const IconSearch = ({ w, h, fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 ${w} ${h}`} w={`${w}px`} h={`${h}px`} fill="none">
            <path
                d="M15.8531 15.1469L11.4313 10.725C12.4063 9.5875 12.9719 8.11563 12.9719 6.5C12.9719 2.90937 10.0616 0 6.47188 0C2.88219 0 0 2.91031 0 6.5C0 10.0897 2.91001 13 6.47188 13C8.08688 13 9.56094 12.4075 10.6969 11.4328L15.1188 15.8547C15.2438 15.95 15.3719 16 15.5 16C15.6281 16 15.7559 15.9512 15.8534 15.8535C16.05 15.6594 16.05 15.3406 15.8531 15.1469ZM6.50001 12C3.44063 12 1.00001 9.53125 1.00001 6.5C1.00001 3.46875 3.44063 1 6.50001 1C9.55938 1 12 3.44062 12 6.5C12 9.55937 9.53126 12 6.50001 12Z"
                fill={fill ? fill : '#333333'}
            />
        </Icon>
    )
}
