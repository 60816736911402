import dayjs from 'dayjs';

export const Months = [
    { value: '01', label: ('477') },
    { value: '02', label: ('478') },
    { value: '03', label: ('479') },
    { value: '04', label: ('480') },
    { value: '05', label: ('481') },
    { value: '06', label: ('482') },
    { value: '07', label: ('483') },
    { value: '08', label: ('484') },
    { value: '09', label: ('485') },
    { value: '10', label: ('486') },
    { value: '11', label: ('487') },
    { value: '12', label: ('488') },
];

export const getDateWithShortMonthName = (dateAndTime: string, t: (key: string) => string) => {

    if (!dateAndTime) {
        return '';
    }
    const date = dayjs(dateAndTime);
    const year = date.format('YYYY') === '1900' ? '' : date.format('YYYY');
    const day = date.format('DD');
    const monthIndex = date.format('MM');
    const tindex = Months.find(month => month.value === monthIndex)?.label || '';

    const fullLocalizedMonth = t(tindex);
    const localizedMonth = fullLocalizedMonth.substring(0, 3);

    return `${day} ${localizedMonth} ${year}`;
};

