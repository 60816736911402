import { Icon } from '@chakra-ui/react'

type Props = {
    w?: string
    h?: string
    fill?: string
}

export const IconUndo = ({ w, h, fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 ${w} ${h}`} w={`${w}px`} h={`${h}px`} fill="none">
            <path
                d="M14 7C14 10.8594 10.8625 13.9969 7.00313 13.9969C4.83406 13.9969 2.81875 13.0188 1.48125 11.3078C1.31131 11.091 1.34991 10.7766 1.56719 10.6066C1.78544 10.4337 2.09938 10.4738 2.26938 10.6925C3.41563 12.1594 5.14062 13 7 13C10.3094 13 13 10.3084 13 7C13 3.69156 10.3084 1 7 1C4.84688 1 2.89062 2.14687 1.81719 4H4.5C4.775 4 5 4.225 5 4.5C5 4.775 4.775 5 4.5 5H0.5C0.22375 5 0 4.775 0 4.5V0.5C0 0.22375 0.22375 0 0.5 0C0.77625 0 1 0.22375 1 0.5V3.42375C2.2625 1.30844 4.51875 0.00343752 7.00313 0.00343752C10.8625 0.00343752 14 3.14062 14 7Z"
                fill={fill ? fill : '#828282'}
            />
        </Icon>
    )
}
